import { keyframes } from 'styled-components';

export const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(80px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`;

export const fadeInDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-80px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`;

export const running = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-50%, 0, 0);
  }
`;

export const runningReverse = keyframes`
  0% {
    transform: translate3d(-50%, 0, 0);
  }
  100% {
    transform: translate3d(0%, 0, 0);
  }
`;

export const animateBar1 = keyframes`
  0% {
      height: 100%;
  }
  25% {
      height: 56%;
  }
  50% {
      height: 75%;
  }
  75% {
      height: 89%;
  }
  100% {
      height: 54%;
  }
`;

export const animateBar2 = keyframes`
  0% {
    height: 100%;

  }
  25% {
      height: 76%;
  }
  50% {
      height: 88%;
  }
  75% {
      height: 63%;
  }
  100% {
      height: 75%;
  }
`;

export const animateBar3 = keyframes`
  0% {
    height: 100%;

  }
  25% {
      height: 100%;
  }
  50% {
      height: 48%;
  }
  75% {
      height: 62%;
  }
  100% {
      height: 94%;
  }
`;

export const animateBar4 = keyframes`
  0% {
    height: 100%;

  }
  25% {
      height: 86%;
  }
  50% {
      height: 76%;
  }
  75% {
      height: 96%;
  }
  100% {
      height: 60%;
  }
`;

export const animateBar5 = keyframes`
  0% {
    height: 100%;
  }
  25% {
      height: 82%;
  }
  50% {
      height: 96%;
  }
  75% {
      height: 63%;
  }
  100% {
      height: 63%;
  }
`;
