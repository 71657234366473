import React from 'react';
import './index.css';
import './styles/animations.css';
import { GlobalStyle } from './styles/global';
import { Start } from './components/Start';
import { Main } from './components/Main';
import { Header } from './components/Header';
import { ParallaxProvider } from 'react-scroll-parallax';
import { Plans } from './components/Plans';
import { Footer } from './components/Footer';

function App() {
	return (
		<div className="App">
			<ParallaxProvider>
				<Header />
				<GlobalStyle />
				<Start />
				<Main />
				<Plans />
				<Footer />
			</ParallaxProvider>
		</div>
	);
}

export default App;
